<template>
  <div style="padding-top: 30vh; background-color: bisque" ref="test-portal"></div>
</template>

<script>
import { customInfoWindowConstruction } from "@/assets/scripts/customInfoWindow";
import { customInfoWindowLogic } from "@/assets/scripts/customInfoWindowLogic";
export default {
  name: "Test",
  mounted() {
    let store = {
      position: {
        lat: 53.58262,
        lng: 7.897697,
      },
      id: "76",
      name: "Testing store",
      mapCoordsLatitude: "53.582620",
      mapCoordsLongitude: "7.897697",
      distance: 0.8012958815130068,
      dealerDetails: {
        dealerName: "Testing store",
        dealerCountryCode: "DE",
        dealerClientLanguage: "en",
        images: {
          storeLogo: "",
          storeThumbnail: "https://static.redseafish.com/static-content/dealer-20240812-c5f990af75341aca46ccf7ccaa28bcba.jpg",
          insideViews: [],
          outsideViews: [],
          productPlacesViews: [],
          videos: [""],
        },
        address1: "test 1",
        address2: " ",
        city: "Berlin",
        state: " ",
        postal: "93612",
        phoneNumber: "123456",
        websiteURL: "test",
        openingTimes: "",
        productTags: "",
        dealerDescription: "",
        distanceImperial: 0.4979020231916185,
      },
    };
    let userPosition = {
      lat: 53.57557869999999,
      lng: 7.9002789,
      zoom: 15,
    };
    let unit = "metric";
    let translations = {
      storefinder_btn_label_email: this.$t("storefinder_btn_label_email"),
      storefinder_btn_label_website: this.$t("storefinder_btn_label_website"),
      storefinder_btn_label_phone: this.$t("storefinder_btn_label_phone"),
      storefinder_btn_label_get_directions: this.$t("storefinder_btn_label_get_directions"),
      storefinder_btn_label_street_view: this.$t("storefinder_btn_label_street_view"),
      storefinder_store_away: this.$t("storefinder_store_away"),
      storefinder_unit_kilometers: this.$t("storefinder_unit_kilometers"),
      storefinder_unit_miles: this.$t("storefinder_unit_miles"),
      storefinder_btn_label_video: this.$t("storefinder_btn_label_video"),
    };
    this.$refs["test-portal"].innerHTML = customInfoWindowConstruction(store, userPosition, unit, translations);
    customInfoWindowLogic(store);
  },
};
</script>

<style scoped>
.overflow-y-scroll {
  overflow-y: auto;
}
</style>
