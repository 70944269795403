export function customInfoWindowLogic(store, map) {
  console.log(store);
  //TODO find more stable solution than a timeout
  setTimeout(() => {
    let infoWindowScrollContainer = document.getElementById("infowindow-horizontal-overflow-container");
    let streetViewBtn = document.getElementById("streetview-btn");
    let closeBtn = document.getElementById("infowindow-close-btn");
    let videoBtn = document.getElementById("infowindow-store-tour-btn");

    infoWindowScrollContainer.addEventListener("mouseover", () => {
      map.setOptions({ gestureHandling: "none" });
    });
    infoWindowScrollContainer.addEventListener("mouseleave", () => {
      map.setOptions({ gestureHandling: "auto" });
    });

    infoWindowScrollContainer.addEventListener("touchstart", () => {
      map.setOptions({ gestureHandling: "none" });
    });
    infoWindowScrollContainer.addEventListener("touchend", () => {
      map.setOptions({ gestureHandling: "auto" });
    });

    let imgArray = [];

    if (store.dealerDetails.images.storeThumbnail) {
      imgArray.push(store.dealerDetails.images.storeThumbnail);
    }

    imgArray.push(...store.dealerDetails.images.outsideViews, ...store.dealerDetails.images.insideViews, ...store.dealerDetails.images.productPlacesViews);
    if (imgArray.length > 0) {
      initInfoWindowImgCarousel(imgArray);
    }

    closeBtn.addEventListener("click", () => {
      window.vueApp.$children[0].$children[0].deHighlightStore();
      window.vueApp.$children[0].$children[0].$children[0].infoWindow.close();
    });

    streetViewBtn.addEventListener("click", () => {
      window.vueApp.$children[0].$children[0].$children[0].addStreetViewPanorama({
        lat: Number(streetViewBtn.getAttribute("data-lat")),
        lng: Number(streetViewBtn.getAttribute("data-lng")),
      });
      window.vueApp.$children[0].$children[0].isStreetView = true;
    });

    if (videoBtn) {
      videoBtn.addEventListener("click", () => {
        window.vueApp.$children[0].$children[0].$refs["store-tour-modal"].open();
      });
    }
  }, 120);
}

function initInfoWindowImgCarousel(imgArray) {
  let carousel = document.getElementById("infowindow-carousel");

  // if there are already children, the carousel has already been created
  if (carousel.children.length > 1) {
    return;
  }

  let tabHolder = document.getElementById("infowindow-carousel-tab-holder");
  let activeSlide = 0;
  carousel.style.display = "block";

  offsetSpike();
  offsetInfowindow();

  for (let i = 0; i < imgArray.length; i++) {
    let constructedImg = constructImg(imgArray[i]);
    let tab = constructTab(i);
    if (i == 0) {
      tab.classList.add("active");
    }
    if (i > 0) {
      constructedImg.classList.add("op0");
    }
    carousel.appendChild(constructedImg);
    if (imgArray.length > 1) {
      tabHolder.appendChild(tab);
      tab.addEventListener("click", () => {
        setInfowindowCarouselSlide(carousel, i);
        activeSlide = i;
      });
    }
  }
  // carousel swipe
  let startX = 0;
  let startY = 0;
  let isSwiping = false;

  carousel.addEventListener("touchstart", (e) => {
    e.stopPropagation();
    const touch = e.touches[0]; // Get the first touch
    startX = touch.pageX;
    startY = touch.pageY;
    isSwiping = true;
  });

  carousel.addEventListener("touchmove", (e) => {
    e.stopPropagation();
    if (!isSwiping) return;

    const touch = e.touches[0];
    const diffX = touch.pageX - startX; // Calculate horizontal movement
    const diffY = touch.pageY - startY; // Calculate vertical movement

    if (Math.abs(diffX) > Math.abs(diffY)) {
      // Horizontal swipe
      if (diffX > 0) {
        activeSlide = activeSlide - 1;
        if (activeSlide < 0) {
          activeSlide = carousel.querySelectorAll(".infowindow-carousel-tab").length - 1;
        }
        setInfowindowCarouselSlide(carousel, activeSlide);
      } else {
        activeSlide = activeSlide + 1;
        if (activeSlide > carousel.querySelectorAll(".infowindow-carousel-tab").length - 1) {
          activeSlide = 0;
        }
        setInfowindowCarouselSlide(carousel, activeSlide);
      }
      isSwiping = false; // Stop after detecting the swipe
    }
  });

  carousel.addEventListener("touchend", () => {
    isSwiping = false; // Reset swiping state
  });
}
function constructTab(i) {
  let div = document.createElement("div");
  div.className = "infowindow-carousel-tab";
  div.dataset.slide = i;
  let innerDiv = document.createElement("div");
  div.appendChild(innerDiv);
  return div;
}
function constructImg(src) {
  let img = document.createElement("img");
  img.src = src;
  img.alt = "Store image";
  img.style.position = "absolute";
  img.style.inset = "0";
  img.style.objectFit = "cover";
  img.style.borderTopLeftRadius = "8px";
  img.style.borderTopRightRadius = "8px";
  img.className = "cover col-12 h100";
  return img;
}

function setInfowindowCarouselSlide(carousel, slide) {
  let imgs = carousel.querySelectorAll("img");
  let tabs = carousel.querySelectorAll(".infowindow-carousel-tab");
  for (let i = 0; i < imgs.length; i++) {
    if (i == slide) {
      imgs[i].classList.remove("op0");
    } else {
      imgs[i].classList.add("op0");
    }
  }

  for (let i = 0; i < tabs.length; i++) {
    if (i == slide) {
      tabs[i].classList.add("active");
    } else {
      tabs[i].classList.remove("active");
    }
  }
}

// function isMobile() {
//   return /Mobi|Android|iPhone|iPad|iPod/i.test(navigator.userAgent);
// }

// if (isMobile()) {
//   console.log("User is on a mobile device");
// } else {
//   console.log("User is not on a mobile device");
// }

function offsetSpike() {
  let spike = document.getElementById("custom-info-window-spike");
  spike.classList.add("spike-carousel-offset");
}

function offsetInfowindow() {
  let infowindow = document.getElementById("custom-info-window");
  infowindow.classList.add("custom-info-window-carousel-offset");
}
