<template>
  <div v-if="store" :class="`p2 pointer ${index == selectedStore ? 'active-outline' : ''} bg-color-white`" style="border-radius: 8px">
    <p :class="`open-sans-bold h5 xl-h4 color-black`" style="margin-bottom: 0.25rem">
      {{ store.name }}
    </p>
    <p :class="`open-sans-regular h7 xl-h6 color-14`">{{ store.dealerDetails.address1 }}{{ store.dealerDetails.address2 ? " " + store.dealerDetails.address2 : "" }}, {{ store.dealerDetails.postal }} {{ store.dealerDetails.city }}</p>
    <div :class="`h1px col-12 bg-color-12`" style="margin: 0.75rem 0"></div>
    <p class="flex items-center" style="gap: 4px">
      <svg width="9" height="13" viewBox="0 0 9 13" fill="none">
        <path d="M8.5 4.60417C8.5 5.75295 8.04112 7.08671 7.24657 8.49422C6.53625 9.75251 5.57637 11.0369 4.5 12.2532C3.42363 11.0369 2.46375 9.75251 1.75343 8.49422C0.958882 7.08671 0.5 5.75295 0.5 4.60417C0.5 2.32665 2.30159 0.5 4.5 0.5C6.69841 0.5 8.5 2.32665 8.5 4.60417Z" stroke="#1867FF" />
        <circle cx="4.5" cy="4.5" r="2" stroke="#1867FF" />
      </svg>
      <span class="h7 xl-h6 open-sans-regular color-1">{{ unit == "metric" ? store.distance.toFixed(1) : store.distanceImperial.toFixed(1) }} {{ unit == "metric" ? $t("storefinder_unit_kilometers") : $t("storefinder_unit_miles") }} {{ $t("storefinder_store_away") }}</span>
    </p>
    <div v-if="hasExitStreetViewBtn" :class="`h1px col-12 bg-color-12`" style="margin: 0.75rem 0"></div>
    <div v-if="hasExitStreetViewBtn" @click="initEscapeStreetView()" target="_blank" rel="noreferrer noopener" class="rsf-btn rsf-btn-std bg-color-1 color-white open-sans-regular h7 flex gap2 items-center" style="font-size: 0.75rem; padding: 0.75rem 1rem">
      <svg style="width: 12px" viewBox="0 0 16 16" fill="none">
        <g clip-path="url(#clip0_1922_48204)">
          <rect width="19.6867" height="2" rx="1" transform="matrix(0.707107 0.707107 0.707107 -0.707107 0.15625 1.58594)" fill="white" />
          <rect x="15.4902" y="1.58594" width="19.6867" height="2" rx="1" transform="rotate(135 15.4902 1.58594)" fill="white" />
        </g>
        <defs>
          <clipPath id="clip0_1922_48204">
            <rect width="16" height="16" fill="white" transform="matrix(-1 0 0 1 16 0)" />
          </clipPath>
        </defs>
      </svg>
      {{ $t("storefinder_btn_label_exit_streetview") }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    store: Object,
    position: Object,
    userPosition: Object,
    showStoreImage: Boolean,
    showArrow: Boolean,
    showOpeningTimes: Boolean,
    showAddress: Boolean,
    showDistance: Boolean,
    index: Number,
    selectedStore: Number,
    hasExitStreetViewBtn: Boolean,
    initEscapeStreetView: Function,
    unit: String,
  },
  name: "StoreInfoSmall",
  data() {
    return {
      expandOpeningTimes: false,
      maxHeight: 0,
      currentHeight: 0,
    };
  },
};
</script>

<style scoped>
.active-outline {
  box-shadow: 0 0 0 1px var(--rsf-2022-color-1);
}
</style>
