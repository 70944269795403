<template>
  <div class="map-flex flex-grow overflow-hidden col-12">
    <!-- mobile input part -->
    <div id="mobile-input" ref="mobile-input" class="lg-hide block p2 bg-color-11">
      <!-- shrinked version -->
      <div v-show="!expandMobileInputs" class="flex justify-between gap2 items-center"
        @click="expandMobileInputs = true">
        <p class="h5 color-14 open-sans-regular">{{ address }}</p>
        <svg width="10" height="7" viewBox="0 0 10 7" fill="none">
          <path d="M8.99023 1.60596L5.00021 5.59598L1.01018 1.60596" stroke="#555D65" stroke-width="1.59601"
            stroke-linecap="round" stroke-linejoin="round" />
        </svg>
      </div>

      <div v-show="expandMobileInputs" class="relative mb2">
        <svg @click="getStoresByAddress" class="bg-color-white px1"
          style="position: absolute; z-index: 1; top: 50%; right: 0.5rem; transform: translateY(-50%)" width="24"
          height="24" viewBox="0 0 24 24" fill="none">
          <rect width="2.2345" height="4.80648" rx="1.11725"
            transform="matrix(-0.711146 0.703044 -0.711146 -0.703044 22.4482 20.8848)" fill="#555D65" />
          <path fill-rule="evenodd" clip-rule="evenodd"
            d="M10.99 19.0461C15.5408 19.0461 19.2299 15.399 19.2299 10.9C19.2299 6.40104 15.5408 2.75391 10.99 2.75391C6.43916 2.75391 2.75 6.40104 2.75 10.9C2.75 15.399 6.43916 19.0461 10.99 19.0461Z"
            stroke="#555D65" stroke-width="1.5" />
        </svg>
        <input ref="address-input-mobile" type="text" class="rsf-input" v-model="address"
          :placeholder="searchesMade > 0 ? $t('storefinder_placeholder_address') : ''" />
      </div>
      <div v-if="expandMobileInputs" class="rsf-select-wrapper relative">
        <svg class="svg-color-14" :style="`transform: rotate(180deg) translateY(50%)`" width="24" height="24"
          viewBox="0 0 24 24" fill="none">
          <path d="M17 15L12 10L7 15" stroke="" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
        <select @change="getStoresByAddress" v-model="radius" class="rsf-select">
          <option v-for="(range, index) in perimeterOptions" :key="index" :value="range.km">{{ unit == "metric" ?
            range.km : range.miles }} {{ unit == "metric" ? $t("storefinder_unit_kilometers") :
              $t("storefinder_unit_miles") }}</option>
        </select>
      </div>
    </div>
    <!-- desktop search bar & results -->
    <div class="hide lg-flex lg-flex-column gap3 col-12 max-width-2 border-box bg-color-11" style="max-width: 420px">
      <div class="vertical-overflow no-scrollbar">
        <!-- top part -->
        <div class="flex flex-column gap2 p3 bg-color-11" style="position: sticky; top: 0; z-index: 2">
          <h2 class="color-black helvetica-bold h1-5">
            {{ $t("storefinder_form_heading") }}
          </h2>
          <div class="relative">
            <svg @click="getStoresByAddress" class="bg-color-white px1"
              style="position: absolute; z-index: 3; top: 50%; right: 0.5rem; transform: translateY(-50%)" width="24"
              height="24" viewBox="0 0 24 24" fill="none">
              <rect width="2.2345" height="4.80648" rx="1.11725"
                transform="matrix(-0.711146 0.703044 -0.711146 -0.703044 22.4482 20.8848)" fill="#555D65" />
              <path fill-rule="evenodd" clip-rule="evenodd"
                d="M10.99 19.0461C15.5408 19.0461 19.2299 15.399 19.2299 10.9C19.2299 6.40104 15.5408 2.75391 10.99 2.75391C6.43916 2.75391 2.75 6.40104 2.75 10.9C2.75 15.399 6.43916 19.0461 10.99 19.0461Z"
                stroke="#555D65" stroke-width="1.5" />
            </svg>

            <input ref="address-input" type="text" class="rsf-input pr3" v-model="address"
              :placeholder="searchesMade > 0 ? $t('storefinder_placeholder_address') : ''" />
          </div>
          <div class="rsf-select-wrapper relative">
            <svg class="svg-color-14" :style="`transform: rotate(180deg) translateY(50%)`" width="24" height="24"
              viewBox="0 0 24 24" fill="none">
              <path d="M17 15L12 10L7 15" stroke="" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
            <select @change="getStoresByAddress" v-model="radius" class="rsf-select">
              <option v-for="(range, index) in perimeterOptions" :key="index" :value="range.km">{{ unit == "metric" ?
                range.km : range.miles }} {{ unit == "metric" ? $t("storefinder_unit_kilometers") :
                  $t("storefinder_unit_miles") }}</option>
            </select>
          </div>
        </div>

        <div v-if="markers.length > 0" class="flex flex-column gap2 px3 pb3 mt1">
          <!-- TODO?: pagination when there are many stores -->
          <div v-for="(store, index) in markers" :key="index" @click="
            highlightStore(
              {
                lat: Number(store.mapCoordsLatitude),
                lng: Number(store.mapCoordsLongitude),
              },
              index,
              store
            )
            ">
            <StoreInfoSmall :position="{
              lat: Number(store.mapCoordsLatitude),
              lng: Number(store.mapCoordsLongitude),
            }" :userPosition="userPosition" :showArrow="true" :showStoreImage="true" :showDistance="true"
              :showOpeningTimes="false" :index="index" :selectedStore="selectedStore" :store="store" :unit="unit" />
          </div>
        </div>
        <!-- initial no serachresults -->
        <div class="flex flex-column justify-center px3 border-box"
          v-if="markers.length == 0 && searchesMade > 0 && !isLoading">
          <p class="h2 open-sans-bold center mb2">{{ $t("storefinder_could_not_find_stores_1") }}<br
              style="display: inline-block !important" />{{ $t("storefinder_could_not_find_stores_2") }}</p>
        </div>
        <!-- loading -->
        <div class="flex flex-column justify-center px3 border-box" v-if="isLoading">
          <div class="maps-loader mx-auto mb2"></div>
          <p class="h4 open-sans-regular center">{{ $t("storefinder_loading_search") }}</p>
        </div>
      </div>
    </div>

    <!-- GOOGLE MAPS -->
    <div class="relative col-12 flex-grow">
      <div :class="`google-maps-loading-screen ${showMap && !apiError ? 'op0' : 'op100'}`">
        <div class="maps-loader"></div>
      </div>
      <GoogleMaps :zoom="8" :center="{ lat: 52.52, lng: 13.405 }" :userPosition="userPosition" :unit="unit">
      </GoogleMaps>

      <!-- StoreItem for Streetview -->
      <div v-if="selectedStore >= 0 && isStreetView" class="streetview-info-container"
        style="position: absolute; left: 0.7rem; z-index: 2; max-width: 400px">
        <StoreInfoSmall :position="{
          lat: Number(markers[selectedStore].mapCoordsLatitude),
          lng: Number(markers[selectedStore].mapCoordsLongitude),
        }" :userPosition="userPosition" :showArrow="true" :showStoreImage="true" :showDistance="true"
          :showOpeningTimes="false" :index="index" :selectedStore="selectedStore" :store="markers[selectedStore]"
          :hasExitStreetViewBtn="true" :initEscapeStreetView="initEscapeStreetView" :unit="unit" />
      </div>

      <!-- Loading -->
      <div v-show="isLoading" class="absolute p1 bg-color-white" style="top: 0.7rem; right: 0.7rem; border-radius: 6px">
        <div class="maps-loader"></div>
      </div>

      <ErrorPopup v-show="markers.length <= 0 && searchesMade > 0 && !isLoading" :clickEvent="null" :showButton="false"
        :text="$t('storefinder_no_stores_found')" :subtext="$t('storefinder_no_stores_subtext')" />
      <ErrorPopup v-show="apiError && !isLoading" :clickEvent="() => (apiError = false)" :showButton="true"
        :text="$t('storefinder_api_error')" />
    </div>

    <!-- mobile store listing -->
    <Draggable>
      <!-- initial no serachresults -->
      <div class="flex flex-column justify-center px3 border-box"
        v-if="markers.length == 0 && searchesMade > 0 && !isLoading">
        <p class="h4 open-sans-bold center mb2">{{ $t("storefinder_could_not_find_stores_1") }}<br
            style="display: inline-block !important" />{{ $t("storefinder_could_not_find_stores_2") }}</p>
      </div>
      <!-- loading -->
      <div class="flex flex-column justify-center px3 border-box pt3" v-if="isLoading">
        <div class="maps-loader mx-auto mb2"></div>
        <p class="h4 open-sans-regular center">{{ $t("storefinder_loading_search") }}</p>
      </div>
      <div v-if="markers.length > 0"
        class="flex-grow overflow-y-scroll no-scrollbar p2 border-box gap2 flex flex-column">
        <div v-for="(store, index) in markers" :key="index" @click="
          highlightStore(
            {
              lat: Number(store.mapCoordsLatitude),
              lng: Number(store.mapCoordsLongitude),
            },
            index,
            store
          )
          ">
          <StoreInfoSmall :store="store" :position="{
            lat: Number(store.mapCoordsLatitude),
            lng: Number(store.mapCoordsLongitude),
          }" :userPosition="userPosition" :showArrow="true" :showStoreImage="true" :showAddress="false"
            :showOpeningTimes="true" :showDistance="true" :index="index" :selectedStore="selectedStore"
            :hasExitStreetViewBtn="false" :unit="unit" />
        </div>
      </div>
    </Draggable>

    <Storefinder_Modal v-show="selectedStore > -1 && markers[selectedStore].dealerDetails.images.videos.length > 0"
      :ref="'store-tour-modal'">
      <video v-if="selectedStore > -1 && markers[selectedStore].dealerDetails.images.videos.length > 0"
        id="rsf-store-tour-video" preload="metadata" playsinline controls class="h100 bg-color-black"
        style="aspect-ratio: 9/16; object-fit: contain; max-height: 70dvh; display: none; position: relative; z-index: -1; "
        alt="Store tour video" :src="markers[selectedStore].dealerDetails.images.videos[0] + '#t=0.1'"></video>
    </Storefinder_Modal>
  </div>
</template>

<script>
import axios from "axios";
import { EventBus } from "@/event-bus";
import GoogleMaps from "./GoogleMaps.vue";
import Storefinder_Modal from "./Storefinder_Modal.vue";
import StoreInfoSmall from "./StoreInfoSmall.vue";
import Draggable from "./Draggable.vue";
import ErrorPopup from "./ErrorPopup.vue";

export default {
  name: "Storefinder",
  props: {
    siteLanguage: String,
  },
  components: {
    GoogleMaps: GoogleMaps,
    Storefinder_Modal: Storefinder_Modal,
    StoreInfoSmall: StoreInfoSmall,
    Draggable: Draggable,
    ErrorPopup: ErrorPopup,
  },
  data() {
    return {
      showMap: false,
      googleMapsLoaded: false,
      coords: {},
      userPosition: {},
      address: "",
      country: {},
      markers: [],
      radius: 250,
      isLoading: false,
      addressNotFound: false,
      selectedStore: -1,
      searchesMade: 0,
      expandMobileResults: false,
      expandMobileInputs: true,
      isStreetView: false,
      apiError: false,
      perimeterOptions: [
        { km: 50, miles: 30 },
        { km: 75, miles: 45 },
        { km: 100, miles: 60 },
        { km: 125, miles: 75 },
        { km: 150, miles: 90 },
        { km: 200, miles: 125 },
        { km: 250, miles: 155 },
        { km: 500, miles: 310 },
      ],
      unit: "metric",
    };
  },
  created() {
    EventBus.$on("google_maps_loaded", () => {
      this.fetchCountry();
      this.googleMapsLoaded = true;
    });
    EventBus.$on("select_store", (position) => {
      this.selectStore(position);
    });
    EventBus.$on("add_streetview_panorama", () => {
      this.isStreetView = true;
    });
    EventBus.$on("escape_street_view", () => {
      this.isStreetView = false;
    });
    EventBus.$on("update_unit_system", (payload) => {
      this.unit = payload.unit;
    });
    EventBus.$on("update_input_value", (place) => {
      if (place != undefined) {
        if ("formatted_address" in place) {
          this.address = place.formatted_address;
        } else {
          this.address = place.name;
        }
      }
      this.getStoresByAddress();
    });
    EventBus.$on("add_info_window", (payload) => {
      console.log("trigger-info-window-for-tracking-call");
      console.log(payload);
    });
  },
  methods: {
    fetchCountry() {
      axios
        .get(`https://data.redseafish.com/public-api/storefinder/initstorefinder/${dfn_user_region}/json`)
        .then((response) => {
          this.address = response.data.countryname;
          if (response.data.countryfound) {
            this.getStoresByAddress();
          } else {
            this.address = this.$t("storefinder_type_in_address");
          }
        })
        .catch(() => { });
    },
    getStoresByAddress() {
      if (this.address == "") {
        return;
      }
      if (this.searchesMade > 0) {
        this.expandMobileInputs = false;
      }
      this.markers = [];
      this.userPosition = {};
      this.selectedStore = -1;
      this.isLoading = true;
      this.addressNotFound = false;
      this.isStreetView = false;
      this.apiError = false;
      this.unit = "metric";
      let that = this;
      let url = process.env.VUE_APP_RSF_API + "/public-api/storefinder/getstoresbyaddress/json";
      EventBus.$emit("close_info_window");
      EventBus.$emit("shrink_draggable");
      axios
        .post(url, { address: that.address, radius: Number(this.radius) })
        .then(function (response) {
          that.isLoading = false;
          if (!response.data.addressFound) {
            that.addressNotFound = true;
            return;
          }
          let position = {
            lat: response.data.mapsViewport.centerLatitude,
            lng: response.data.mapsViewport.centerLongitude,
            zoom: response.data.mapsViewport.zoomLevel,
          };
          that.userPosition = position;

          that.markers = that.addImperialDistanceToMarkers(response.data.mapPoints);
          that.formatCoords();
          EventBus.$emit("load_markers", that.markers);
          EventBus.$emit("draw_circle", {
            position: position,
            radius: that.radius,
          });
          EventBus.$emit("determine_country", position);
          that.checkForHighlightedStore();
          that.searchesMade++;
          setTimeout(() => {
            that.showMap = true;
          }, 300)
        })
        .catch(function () {
          that.apiError = true;
        });
    },
    checkForHighlightedStore() {
      for (let i = 0; i < this.markers.length; i++) {
        if (this.markers[i].distance < 0.1) {
          this.selectedStore = i;
          this.highlightStore(
            {
              lat: Number(this.markers[i].mapCoordsLatitude),
              lng: Number(this.markers[i].mapCoordsLongitude),
            },
            i,
            this.markers[i]
          );
        }
      }
    },
    addImperialDistanceToMarkers(markers) {
      for (let i = 0; i < markers.length; i++) {
        markers[i].distance = Number(markers[i].distance);
        markers[i].distanceImperial = Number(markers[i].distance) * 0.621371;
      }
      return markers;
    },
    clearAllMarkers() {
      EventBus.$emit("clear_all_markers");
    },
    clearSingleMarker() {
      EventBus.$emit("clear_single_marker", 0);
    },
    initEscapeStreetView() {
      this.isStreetView = false;
      EventBus.$emit("escape_streetview");
    },
    highlightStore(position, index, store) {
      this.selectedStore = index;
      this.isStreetView = false;
      EventBus.$emit("highlight_marker", {
        position: position,
        store: store,
      });
      EventBus.$emit("add_info_window", {
        position: position,
        store: store,
      });
    },
    deHighlightStore() {
      this.selectedStore = -1;
      EventBus.$emit("dehighlight_marker");
    },
    selectStore(position) {
      for (let i = 0; i < this.markers.length; i++) {
        if (Number(this.markers[i].mapCoordsLatitude) == position.lat && Number(this.markers[i].mapCoordsLongitude) == position.lng) {
          this.selectedStore = i;
          EventBus.$emit("add_info_window", {
            position: position,
            store: this.markers[i],
          });
          return;
        }
      }
    },
    formatCoords() {
      for (let i = 0; i < this.markers.length; i++) {
        this.markers[i].mapCoordsLatitude = this.replaceCommaWithDot(this.markers[i].mapCoordsLatitude);
        this.markers[i].mapCoordsLongitude = this.replaceCommaWithDot(this.markers[i].mapCoordsLongitude);
      }
    },
    replaceCommaWithDot(str) {
      if (str.includes(",")) {
        return str.replace(",", ".");
      } else return str;
    },
  },
};
</script>

<style scoped>
.vertical-overflow {
  overflow-y: auto;
  box-sizing: border-box;
  flex: 1 1 auto;
  height: 1px;
  cursor: pointer;
}

/* The slider itself */
.slider {
  -webkit-appearance: none;
  /* Override default CSS styles */
  appearance: none;
  width: 100%;
  /* Full-width */
  height: 2px;
  /* Specified height */
  background: var(--rsf-2022-color-12);
  /* Grey background */
  outline: none;
}

/* The slider handle (use -webkit- (Chrome, Opera, Safari, Edge) and -moz- (Firefox) to override default look) */
.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  /* Override default look */
  appearance: none;
  width: 25px;
  /* Set a specific slider handle width */
  height: 25px;
  border-radius: 999px;
  /* Slider handle height */
  background: var(--rsf-2022-color-1);
  /* Green background */
  cursor: pointer;
  /* Cursor on hover */
}

.slider::-moz-range-thumb {
  width: 25px;
  /* Set a specific slider handle width */
  height: 25px;
  border-radius: 999px;
  /* Slider handle height */
  background: var(--rsf-2022-color-1);
  /* Green background */
  cursor: pointer;
  /* Cursor on hover */
}

.btn-inactive {
  pointer-events: none;
  opacity: 0.5;
}

.map-flex {
  display: flex;
  flex-direction: row;
}

@media (max-width: 1024px) {
  .map-flex {
    flex-direction: column;
  }
}

/* HTML: <div class="loader"></div> */
.maps-loader {
  width: 16px;
  aspect-ratio: 1;
  border-radius: 50%;
  border: 4px solid var(--rsf-2022-color-1);
  animation: l20-1 0.8s infinite linear alternate, l20-2 1.6s infinite linear;
}

@keyframes l20-1 {
  0% {
    clip-path: polygon(50% 50%, 0 0, 50% 0%, 50% 0%, 50% 0%, 50% 0%, 50% 0%);
  }

  12.5% {
    clip-path: polygon(50% 50%, 0 0, 50% 0%, 100% 0%, 100% 0%, 100% 0%, 100% 0%);
  }

  25% {
    clip-path: polygon(50% 50%, 0 0, 50% 0%, 100% 0%, 100% 100%, 100% 100%, 100% 100%);
  }

  50% {
    clip-path: polygon(50% 50%, 0 0, 50% 0%, 100% 0%, 100% 100%, 50% 100%, 0% 100%);
  }

  62.5% {
    clip-path: polygon(50% 50%, 100% 0, 100% 0%, 100% 0%, 100% 100%, 50% 100%, 0% 100%);
  }

  75% {
    clip-path: polygon(50% 50%, 100% 100%, 100% 100%, 100% 100%, 100% 100%, 50% 100%, 0% 100%);
  }

  100% {
    clip-path: polygon(50% 50%, 50% 100%, 50% 100%, 50% 100%, 50% 100%, 50% 100%, 0% 100%);
  }
}

@keyframes l20-2 {
  0% {
    transform: scaleY(1) rotate(0deg);
  }

  49.99% {
    transform: scaleY(1) rotate(135deg);
  }

  50% {
    transform: scaleY(-1) rotate(0deg);
  }

  100% {
    transform: scaleY(-1) rotate(-135deg);
  }
}

.h1-5 {
  font-size: 1.75rem;
}

.rsf-input.pr3 {
  padding-right: 3rem;
}

.streetview-info-container {
  bottom: 6.7rem;
}

@media (min-width: 1024px) {
  .streetview-info-container {
    bottom: 0.7rem;
  }
}

.google-maps-loading-screen {
  background-color: var(--rsf-2022-color-11);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  position: absolute;
  inset: 0;
  z-index: 2;
  transition: opacity .2s ease;
  pointer-events: none;
}
</style>
