<template>
  <div @click="close()"
    :class="`stb_modal border-box px2 py2 ${showModal ? 'stb_modal_active' : 'pointer-events-none'}`">
    <div @click.stop="" class="relative overflow-hidden bg-color-black"
      style="border-radius: 8px; max-height: 69dvh; aspect-ratio: 9 / 16; height: 100%;">
      <!-- Close button -->
      <button id="storefinder-video-modal-close-btn" @click="close()"
        class="rsf-video-modal-close-btn pointer bg-color-6"
        style="position: absolute; top: 0.5rem; right: 0.5rem; width: 2rem; height: 2rem; border-radius: 999px; pointer-events: none;">
        <svg class="svg-color-white" style="position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%)"
          width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0_326_9822)">
            <rect width="19.6867" height="2" rx="1"
              transform="matrix(0.707107 0.707107 0.707107 -0.707107 0.154785 1.58563)" fill="black" />
            <rect x="15.4895" y="1.58575" width="19.6867" height="2" rx="1" transform="rotate(135 15.4895 1.58575)"
              fill="black" />
          </g>
          <defs>
            <clipPath id="clip0_326_9822">
              <rect width="16" height="16" fill="white" transform="matrix(-1 0 0 1 16 0)" />
            </clipPath>
          </defs>
        </svg>
      </button>
      <!-- Slot for content -->
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "Storefinder_Modal",
  props: {},
  data() {
    return {
      showModal: false,
    };
  },
  mounted() {
    // this.setWindowHeight();
    // document.addEventListener("scroll", () => {
    //   this.setWindowHeight();
    // });
    // document.addEventListener("resize", () => {
    //   this.setWindowHeight();
    // });
  },
  methods: {
    open() {
      let storeVideo = document.getElementById("rsf-store-tour-video");
      let storeVideoModalCloseBtn = document.getElementById("storefinder-video-modal-close-btn");
      storeVideoModalCloseBtn.style.pointerEvents = 'all';
      storeVideo.style.display = 'block';
      this.showModal = true;
    },
    close() {
      this.showModal = false;
      let storeVideoModalCloseBtn = document.getElementById("storefinder-video-modal-close-btn");
      let storeVideo = document.getElementById("rsf-store-tour-video");
      setTimeout(() => {
        storeVideoModalCloseBtn.style.pointerEvents = 'none';
        storeVideo.style.display = 'none';
      }, 300)
    },
    setWindowHeight() {
      this.windowHeight = window.innerHeight;
    },
  },
};
</script>
<style scoped></style>
