<template>
  <router-view :siteLanguage="siteLanguage"></router-view>
</template>

<script>
export default {
  name: "App",
  props: ["siteLanguage"],
  components: {},
  data() {
    return {};
  },
  created() {
    if (this.siteLanguage == "") {
      this.$i18n.locale = "en";
      this.switchLocale();
    } else {
      this.$i18n.locale = this.siteLanguage;
      this.switchLocale();
    }
  },
  mounted() {},
  methods: {
    switchLocale() {
      var locale = this.$i18n.locale;
      const to = this.$router.resolve({ params: { lang: locale } });
      this.$router.push(to.location).catch((err) => {
        if (err.name !== "NavigationDuplicated" && !err.message.includes("Avoided redundant navigation to current location")) {
          // But print any other errors to the console
          console.log(err);
        }
      });
    },
  },
};
</script>

<style>
html,
body {
  height: 100%;
}

body {
  display: flex;
  flex-direction: column;
}

.wp-site-blocks {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.entry-content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.flex-grow {
  flex-grow: 1;
}

.rounded-box {
  border-radius: 8px;
}

.overflow-y-scroll {
  overflow-y: auto;
}

.overflow-x {
  overflow-x: auto;
}

.overflow-x-scroll {
  overflow-x: auto;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.pin-width {
  width: 28px;
}

.pin-width-highlighted {
  width: 38px;
}

#custom-info-window {
  width: 248px;
  transform: translateY(calc(-50% - 2rem));
}

#custom-info-window {
  width: 248px;
  transform: translateY(calc(-50% - 2rem)) translateX(2.5rem);
}

#custom-info-window.custom-info-window-carousel-offset {
  transform: translateY(calc(-50% - 0.8rem)) translateX(2.5rem);
}

#custom-info-window-spike {
  width: 1.5rem;
  height: 1.5rem;
  transform: translateY(-50%) rotate(45deg);
  position: absolute;
  left: -0.8rem;
  top: 50%;
  z-index: -1;
}

#custom-info-window-spike.spike-carousel-offset {
  top: 157px;
}

#infowindow-carousel {
  aspect-ratio: 16/9;
}

@media (min-width: 1920px) {
  .h5.xl-h4 {
    font-size: var(--h4);
  }

  .h7.xl-h6 {
    font-size: var(--h6);
  }

  .pin-width {
    width: 38px;
  }

  .pin-width-highlighted {
    width: 50px;
  }
}

#infowindow-horizontal-overflow-container {
  -webkit-overflow-scrolling: touch;
}

@media (min-width: 768px) {
  #infowindow-horizontal-overflow-container {
    flex-wrap: wrap;
  }
  #custom-info-window {
    width: 420px;
  }
  #infowindow-horizontal-overflow-container > a {
    width: calc(50% - 0.25rem);
  }
  .custom-info-window-btn-label {
    width: 80px;
  }
  /* #infowindow-horizontal-overflow-container > a {
    width: 100%;
  } */
  #custom-info-window-spike.spike-carousel-offset {
    top: 255px;
  }
  #custom-info-window.custom-info-window-carousel-offset {
    transform: translateY(calc(-50% - 2.7rem)) translateX(2.5rem);
  }
}

/* @media (min-width: 1024px) {
  .custom-info-window-btn-label {
    width: auto;
    justify-content: start;
  }
} */

.infowindow-carousel-tab {
  padding: 0.2rem 0;
  cursor: pointer;
}

.infowindow-carousel-tab > div {
  width: 1.25rem;
  height: 3px;
  background-color: var(--rsf-2022-color-13);
  border-radius: 999px;
}

.infowindow-carousel-tab.active > div {
  height: 4px;
  background-color: var(--rsf-2022-color-white);
}

#infowindow-carousel::before {
  display: block;
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  inset: 0;
  background: rgb(0, 0, 0);
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0) 33%);
  z-index: 2;
  pointer-events: none;
}
</style>
